import { useEffect, useState } from "react"
import Page from "../components/Page"
// import { allPermissions, hasPermission } from "../utils/permissions"
import useFetchWithMsal from "../hooks/useFetchWithMsal"
import LinearProgress from "@mui/material/LinearProgress"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import "../styles/CreateSalesOrder.css"
import Dialog from "@mui/material/Dialog"
import Box from "@mui/material/Box"
import { useNavigate } from "react-router-dom"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { IconButton, TextField } from "@mui/material"
import { getStates } from "../utils/salesUtils"
import { ProductPicker } from "../components/ProductPicker"
import DeleteIcon from "@mui/icons-material/Delete"

const defaultOrderItem = {
  productId: null,
  cases: 0,
  bottles: 0,
  price: 0,
}

const defaultOrder = {
  salesOrderTypeId: null,
  requiredDeliveryDate: null,
  rName: "",
  rState: "",
  rStreet: "",
  rCity: "",
  rZip: "",
  rCountry: "United States",
  notes: "",
  items: [],
  additionalInfo: {
    eventOrRetail: "Retail",
    eventTempLicense: "No",
    eventTempLicenseType: "",
    eventNonProfit: "No",
    eventNonProfitName: "",
  },
}

const states = getStates()

const YesOrNo = ({ value, onChange }) => {
  return (
    <select value={value} onChange={(e) => onChange(e.target.value)}>
      <option value="Yes">Yes</option>
      <option value="No">No</option>
    </select>
  )
}

const validateOrder = (order) => {
  if (!order.salesOrderTypeId) {
    return "Order Type is required"
  }
  if (!order.requiredDeliveryDate) {
    return "Delivery Date is required"
  }
  if (!order.rName) {
    return "Recipient Name is required"
  }
  if (!order.rStreet) {
    return "Recipient Street is required"
  }
  if (!order.rCity) {
    return "Recipient City is required"
  }
  if (!order.rState) {
    return "Recipient State is required"
  }
  if (!order.rZip) {
    return "Recipient Zip is required"
  }
  if (!order.rCountry) {
    return "Recipient Country is required"
  }
  if (!order.items.length) {
    return "At least one product is required"
  }
  for (let line of order.items) {
    if (!line.productId) {
      return "Product is required"
    }
    if (!line.cases && !line.bottles) {
      return "Cases or Bottles are required"
    }
  }
  return null
}

export const CreateSalesOrderPage = () => {
  const navigate = useNavigate()
  const [order, setOrder] = useState(defaultOrder)
  const [posting, setPosting] = useState(false)
  const [validation, setValidation] = useState(null)

  const {
    execute: executeGetReferenceData,
    data,
    isLoading,
  } = useFetchWithMsal()
  const { execute: executePost } = useFetchWithMsal()

  useEffect(() => {
    if (!data && !isLoading) {
      executeGetReferenceData("GET", "/api/sales/md")
    }
  }, [executeGetReferenceData, data, isLoading])

  const sendValidation = async () => {
    setPosting(true)
    const resp = await executePost("POST", "/api/sales/validate", order)
    setPosting(false)

    if (resp?.error) {
      alert(resp.error)
      return
    }

    setValidation(resp)
  }

  const sendCreate = async () => {
    setPosting(true)
    const resp = await executePost("POST", "/api/sales/create", order)
    setPosting(false)

    if (resp?.error) {
      alert(resp.error)
      return
    }

    navigate("/orders")
  }

  return (
    <Page fullHeight className="CreateSalesOrderPage">
      {(isLoading || posting) && (
        <div className="loadingBar">
          <LinearProgress />
        </div>
      )}
      <div className="ribbon">
        <Stack direction="row" spacing={1}>
          <div></div>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => navigate("/orders")}
            endIcon={<OpenInNewIcon />}
          >
            Your Orders
          </Button>
        </Stack>
      </div>
      <div className="body">
        <div className="formCont">
          <div className="title">Create a New Order</div>
          <div className="formSection">
            <div className="formRow">
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  gap: "10px",
                }}
              >
                <label htmlFor="orderType" className="label">
                  Order Type
                </label>
                <select
                  name="orderType"
                  id="orderType"
                  onChange={(e) =>
                    setOrder({ ...order, salesOrderTypeId: e.target.value })
                  }
                >
                  <option value="">Select Order Type</option>
                  {data?.salesOrderTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.description}
                    </option>
                  ))}
                </select>
              </div>
              <div
                style={{
                  flex: 1,
                }}
              >
                <TextField
                  fullWidth
                  label="Delivery Date"
                  type="date"
                  value={order.requiredDeliveryDate || ""}
                  onChange={(e) =>
                    setOrder({ ...order, requiredDeliveryDate: e.target.value })
                  }
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </div>
            </div>
            <div className="formRow">
              <TextField
                value={order.rName || ""}
                label="Recipient Name"
                onChange={(e) => setOrder({ ...order, rName: e.target.value })}
                fullWidth
                required
              />
            </div>
            <div className="formRow">
              <TextField
                value={order.rStreet || ""}
                label="Recipient Street"
                onChange={(e) =>
                  setOrder({ ...order, rStreet: e.target.value })
                }
                fullWidth
                required
              />
            </div>
            <div className="formRow">
              <TextField
                value={order.rCity || ""}
                label="Recipient City"
                onChange={(e) => setOrder({ ...order, rCity: e.target.value })}
                fullWidth
                required
              />
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  gap: "10px",
                }}
              >
                <label htmlFor="rState" className="label">
                  State
                </label>
                <select
                  name="rState"
                  id="rState"
                  onChange={(e) =>
                    setOrder({ ...order, rState: e.target.value })
                  }
                >
                  <option value="">Select State</option>
                  {states.map((state) => (
                    <option key={state.value} value={state.value}>
                      {state.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="formRow">
              <TextField
                value={order.rZip || ""}
                label="Recipient Zip"
                onChange={(e) => setOrder({ ...order, rZip: e.target.value })}
                fullWidth
                required
              />
              <TextField
                value={order.rCountry || ""}
                label="Recipient Country"
                onChange={(e) =>
                  setOrder({ ...order, rCountry: e.target.value })
                }
                fullWidth
                required
              />
            </div>
            <div className="formRow">
              <TextField
                value={order.notes || ""}
                label="Notes"
                onChange={(e) => setOrder({ ...order, notes: e.target.value })}
                fullWidth
                rows={2}
                multiline
              />
            </div>
            <div className="itemsCont">
              <div className="header">Products</div>
              <div>
                {order.items?.map((line, i) => {
                  const error = !line.productId
                    ? "Product is required"
                    : !line.cases && !line.bottles
                    ? "Cases or Bottles are required"
                    : null

                  return (
                    <div key={i} className={`itemRow ${error ? "error" : ""}`}>
                      {error && <div className="errorBox">{error}</div>}
                      <div className="itemRowContent">
                        <div className="fields">
                          <div
                            style={{
                              flex: 1,
                            }}
                          >
                            <ProductPicker
                              products={data?.products}
                              id={line.productId}
                              onChange={(productId) =>
                                setOrder({
                                  ...order,
                                  items: order.items.map((item, index) =>
                                    index === i ? { ...item, productId } : item
                                  ),
                                })
                              }
                            />
                          </div>
                          <div
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              gap: "10px",
                            }}
                          >
                            <TextField
                              size="small"
                              label="Cases"
                              type="number"
                              style={{ width: "100px" }}
                              value={line.cases}
                              onChange={(e) =>
                                setOrder({
                                  ...order,
                                  items: order.items.map((item, index) =>
                                    index === i
                                      ? {
                                          ...item,
                                          cases: parseInt(e.target.value),
                                        }
                                      : item
                                  ),
                                })
                              }
                            />
                            <TextField
                              label="Bottles"
                              type="number"
                              size="small"
                              style={{ width: "100px" }}
                              value={line.bottles}
                              onChange={(e) =>
                                setOrder({
                                  ...order,
                                  items: order.items.map((item, index) =>
                                    index === i
                                      ? {
                                          ...item,
                                          bottles: parseInt(e.target.value),
                                        }
                                      : item
                                  ),
                                })
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <IconButton
                            onClick={() =>
                              setOrder({
                                ...order,
                                items: [
                                  ...order.items.slice(0, i),
                                  ...order.items.slice(i + 1),
                                ],
                              })
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() =>
                    setOrder({
                      ...order,
                      items: [...order.items, { ...defaultOrderItem }],
                    })
                  }
                >
                  Add Line
                </Button>
              </div>
            </div>
            <div className="formSection">
              <div className="header">Compliance</div>
              <div>
                <div className="formRow">
                  <div>
                    Is the recipient a retail licensee (on or off premise) or is
                    the event being held on a licensed premises?
                  </div>
                  <select
                    value={order.additionalInfo.eventOrRetail}
                    onChange={(e) =>
                      setOrder({
                        ...order,
                        additionalInfo: {
                          ...order.additionalInfo,
                          eventOrRetail: e.target.value,
                        },
                      })
                    }
                  >
                    <option value="Retail">Retail</option>
                    <option value="Event">Event</option>
                  </select>
                </div>
              </div>
              {order.additionalInfo.eventOrRetail === "Event" && (
                <div>
                  <div className="formRow">
                    <div>
                      Will the event host hold a temporary license or permit?
                    </div>
                    <YesOrNo
                      value={order.additionalInfo.eventTempLicense}
                      onChange={(value) =>
                        setOrder({
                          ...order,
                          additionalInfo: {
                            ...order.additionalInfo,
                            eventTempLicense: value,
                          },
                        })
                      }
                    />
                    {order.additionalInfo.eventTempLicense === "Yes" && (
                      <div>
                        <TextField
                          size="small"
                          value={order.additionalInfo.eventTempLicenseType}
                          label="License Type"
                          onChange={(e) =>
                            setOrder({
                              ...order,
                              additionalInfo: {
                                ...order.additionalInfo,
                                eventTempLicenseType: e.target.value,
                              },
                            })
                          }
                          fullWidth
                        />
                      </div>
                    )}
                  </div>
                  <div className="formRow">
                    <div>Is there a nonprofit/501c3 org involved?</div>
                    <YesOrNo
                      value={order.additionalInfo.eventNonProfit}
                      onChange={(value) =>
                        setOrder({
                          ...order,
                          additionalInfo: {
                            ...order.additionalInfo,
                            eventNonProfit: value,
                          },
                        })
                      }
                    />
                    {order.additionalInfo.eventNonProfit === "Yes" && (
                      <div>
                        <TextField
                          size="small"
                          value={order.additionalInfo.eventNonProfitName}
                          label="Nonprofit Name"
                          onChange={(e) =>
                            setOrder({
                              ...order,
                              additionalInfo: {
                                ...order.additionalInfo,
                                eventNonProfitName: e.target.value,
                              },
                            })
                          }
                          fullWidth
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  const error = validateOrder(order)
                  if (error) {
                    alert(error)
                    return
                  }

                  sendValidation()
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
      {validation && (
        <Dialog
          fullWidth
          maxWidth="md"
          open={!!validation}
          onClose={() => setValidation(null)}
        >
          <Box style={{ padding: "10px" }}>
            <div className="ComplianceNotes">
              <div className="header">Compliance Guidance</div>
              <div className="section">
                <div>
                  Type:{" "}
                  {
                    data.salesOrderTypes.find(
                      (type) => type.id === order.salesOrderTypeId
                    )?.description
                  }
                </div>
              </div>
              {validation?.orderType?.complianceNotes && (
                <div className="section">
                  <div>{validation?.orderType?.complianceNotes}</div>
                </div>
              )}
              <div>
                State:{" "}
                {states.find((state) => state.value === order.rState)?.label}
              </div>
              {!validation?.compliance && (
                <div className="section">
                  <div>No compliance guidance for this state/order type</div>
                </div>
              )}
              {validation?.compliance?.allowed && (
                <div className="section">
                  <div className="h">Allowed?</div>
                  <div>{validation?.compliance?.allowed}</div>
                </div>
              )}
              {validation?.compliance?.notes && (
                <div className="section">
                  <div className="h">Notes</div>
                  <div>{validation?.compliance?.notes}</div>
                </div>
              )}
              {validation?.compliance?.quantityRestrictions && (
                <div className="section">
                  <div className="h">Quantity Restrictions</div>
                  <div>{validation?.compliance?.quantityRestrictions}</div>
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "50px",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => sendCreate()}
              >
                Create Order
              </Button>
              <Button variant="outlined" onClick={() => setValidation(null)}>
                Cancel
              </Button>
            </div>
          </Box>
        </Dialog>
      )}
    </Page>
  )
}
