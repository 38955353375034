import { useState, useEffect } from "react"
import Page from "../components/Page"
import useFetchWithMsal from "../hooks/useFetchWithMsal"
import LinearProgress from "@mui/material/LinearProgress"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import "../styles/B2B.css"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { useNavigate } from "react-router-dom"
import { Dialog, Box } from "@mui/material"
import CreateDemand from "../components/CreateDemand"
import { DataGrid, gridClasses, GridToolbar } from "@mui/x-data-grid"
import WorkDetail from "../components/WorkDetail"

const DEMAND_LIST_URL = "/api/demand?type=B2B"

export const B2BPage = () => {
  const navigate = useNavigate()
  const [newDemand, setNewDemand] = useState(null)
  const [selectedDemand, setSelectedDemand] = useState(null)

  const { execute: executePostNewDemand, isLoading: newDemandLoading } =
    useFetchWithMsal()
  const {
    execute: executeGetDemands,
    isLoading: listLoading,
    data: demandsList,
  } = useFetchWithMsal()

  const handleCreateNewDemand = async (newDemandInput) => {
    await executePostNewDemand("POST", DEMAND_LIST_URL, newDemandInput)
    setNewDemand(null)
    executeGetDemands("GET", DEMAND_LIST_URL)
  }

  useEffect(() => {
    executeGetDemands("GET", DEMAND_LIST_URL)
  }, [executeGetDemands])

  return (
    <Page fullHeight className="B2B">
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
        }}
      >
        {(newDemandLoading || listLoading) && (
          <div className="loadingBar">
            <LinearProgress />
          </div>
        )}
        <div className="ribbon">
          <Stack direction="row" spacing={1}>
            <div></div>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => setNewDemand({})}
            >
              NEW DEMAND
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => navigate("/work/categories")}
              endIcon={<OpenInNewIcon />}
            >
              Work
            </Button>
          </Stack>
        </div>
        <div className="body">
          <div
            style={{
              width: "100%",
              height: "100%",
              flex: 1,
              maxHeight: "100%",
            }}
            className="gridContainer"
          >
            <DataGrid
              rows={demandsList || []}
              columns={[
                {
                  field: "id",
                  headerName: "ID",
                  width: 60,
                },
                {
                  field: "customer",
                  headerName: "Customer",
                  flex: 1,
                  minWidth: 300,
                  valueGetter: (params) =>
                    params.row.externalSalesContract?.customerName ||
                    params.row.customer,
                },
                {
                  field: "description",
                  headerName: "Description",
                  flex: 1,
                  minWidth: 300,
                  valueGetter: (params) =>
                    params.row.externalSalesContract?.description ||
                    params.row.description,
                },
                {
                  field: "contractVintage",
                  headerName: "Vintage",
                  width: 100,
                  valueGetter: (params) =>
                    params.row.externalSalesContract?.vintage ||
                    params.row.contractVintage,
                },
                {
                  field: "contractBlend",
                  headerName: "Blend",
                  width: 120,
                  valueGetter: (params) =>
                    params.row.externalSalesContract?.blend ||
                    params.row.contractBlend,
                },
                {
                  field: "contractVolume",
                  headerName: "Gallons",
                  width: 100,
                  valueGetter: (params) =>
                    params.row.externalSalesContract
                      ? params.row.externalSalesContract.contractGallons
                      : params.row.contractVolume,
                },
                {
                  field: "contractPrice",
                  headerName: "Price",
                  width: 80,
                  valueGetter: (params) =>
                    params.row.externalSalesContract
                      ? params.row.externalSalesContract.price
                      : params.row.contractPrice,
                },
                {
                  field: "tsm",
                  headerName: "TSM",
                  width: 100,
                  valueGetter: (params) =>
                    params.row.externalSalesContract?.contract || "-",
                },
                {
                  field: "edit",
                  headerName: "Edit",
                  width: 60,
                  renderCell: (params) => {
                    return (
                      <button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                          setSelectedDemand({ type: "edit", data: params.row })
                        }
                      >
                        Edit
                      </button>
                    )
                  },
                },
                {
                  field: "work",
                  headerName: "Work",
                  width: 100,
                  renderCell: (params) => {
                    return (
                      <button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                          setSelectedDemand({ type: "work", data: params.row })
                        }
                      >
                        Work ({params.row.plannedWorks?.length || 0})
                      </button>
                    )
                  },
                },
              ]}
              sortModel={[
                {
                  field: "id",
                  sort: "desc",
                },
              ]}
              hideFooterSelectedRowCount={true}
              disableDensitySelector
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              slots={{ toolbar: GridToolbar }}
              pagination
              getRowHeight={() => "auto"}
              getEstimatedRowHeight={() => 40}
              columnHeaderHeight={40}
              pageSizeOptions={[100]}
              sx={{
                [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                  {
                    outline: "none",
                  },
                [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                  {
                    outline: "none",
                  },
              }}
            />
          </div>
        </div>
        {selectedDemand && (
          <div className="selectedCont">
            {selectedDemand.type === "work" && (
              <WorkDetail
                demand={selectedDemand.data}
                close={() => setSelectedDemand(null)}
              />
            )}
            {selectedDemand.type === "edit" && (
              <div
                style={{
                  flex: 1,
                  maxWidth: "500px",
                }}
              >
                <CreateDemand
                  newDemand={selectedDemand.data}
                  handleCreateNewDemand={(i) => handleCreateNewDemand(i)}
                  close={() => setSelectedDemand(null)}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <Dialog fullWidth open={!!newDemand} onClose={() => setNewDemand(null)}>
        <Box style={{ padding: "10px" }}>
          {newDemand && (
            <CreateDemand
              newDemand={null}
              handleCreateNewDemand={(i) => handleCreateNewDemand(i)}
              close={() => setNewDemand(null)}
            />
          )}
        </Box>
      </Dialog>
    </Page>
  )
}
