import {
  Button,
  Stack,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material"
import { useState, useEffect } from "react"
import useFetchWithMsal from "../hooks/useFetchWithMsal"

const defaultDemand = {
  id: null,
  type: "B2B",
  description: "",
  allocationBlend: "",
  bomType: "Single",
  sku: "",
  customer: "",
  contractVolume: 0,
  contractPrice: 0,
  contractBlend: "",
  contractVintage: "",
  externalContractId: null,
}

const CONTRACT_LIST_URL = "/api/demand/contracts"

const CreateDemand = ({
  newDemand: providedDemand,
  handleCreateNewDemand,
  close,
}) => {
  const [newDemand, setNewDemand] = useState(providedDemand || defaultDemand)
  const { execute: executeGetContracts, data: contractsList } =
    useFetchWithMsal()

  useEffect(() => {
    setNewDemand(providedDemand || defaultDemand)
  }, [providedDemand])

  useEffect(() => {
    executeGetContracts(
      "GET",
      CONTRACT_LIST_URL + (!newDemand?.id ? `?unlinked=1` : "")
    )
  }, [executeGetContracts, newDemand?.id])

  return (
    <div className="CreateDemand form">
      <div className="header">NEW DEMAND</div>
      <div className="formRow">
        <FormControl fullWidth>
          <InputLabel id="type-label">Type</InputLabel>
          <Select
            labelId="type-label"
            value={newDemand.type}
            onChange={(e) =>
              setNewDemand({ ...newDemand, type: e.target.value })
            }
            label="Type"
          >
            <MenuItem value={"B2B"}>B2B</MenuItem>
            {/* <MenuItem value={"CG"}>CG</MenuItem> */}
          </Select>
        </FormControl>
      </div>
      <div className="formRow">
        <FormControl fullWidth>
          <InputLabel id="contract-label">Contract</InputLabel>
          <Select
            labelId="contract-label"
            value={newDemand.externalContractId || ""}
            onChange={(e) =>
              setNewDemand({ ...newDemand, externalContractId: e.target.value })
            }
            label="Contract"
          >
            <MenuItem key={""} value={null}>
              None
            </MenuItem>
            {contractsList ? (
              contractsList.map((contract) => (
                <MenuItem key={contract.id} value={contract.id}>
                  {contract.id} - {contract.customerName}
                </MenuItem>
              ))
            ) : (
              <MenuItem
                key={"loading"}
                value={newDemand.externalContractId || "loading"}
              >
                {newDemand.externalContractId ? "Loading..." : "No Contracts"}
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </div>
      {!newDemand.externalContractId && (
        <>
          <div className="formRow">
            <TextField
              fullWidth
              label="Description"
              value={newDemand.description}
              onChange={(e) =>
                setNewDemand({ ...newDemand, description: e.target.value })
              }
            />
          </div>
          <div className="formRow">
            <TextField
              fullWidth
              label="Customer"
              value={newDemand.customer || ""}
              onChange={(e) =>
                setNewDemand({ ...newDemand, customer: e.target.value })
              }
            />
          </div>
          <div className="formRow">
            <TextField
              fullWidth
              label="Blend"
              value={newDemand.contractBlend || ""}
              onChange={(e) =>
                setNewDemand({ ...newDemand, contractBlend: e.target.value })
              }
            />
          </div>
          <div className="formRow">
            <TextField
              fullWidth
              label="Vintage"
              value={newDemand.contractVintage || ""}
              onChange={(e) =>
                setNewDemand({ ...newDemand, contractVintage: e.target.value })
              }
            />
          </div>
          <div className="formRow">
            <TextField
              fullWidth
              label="Contract Volume"
              value={newDemand.contractVolume || 0}
              onChange={(e) =>
                setNewDemand({ ...newDemand, contractVolume: e.target.value })
              }
            />
          </div>
          <div className="formRow">
            <TextField
              fullWidth
              label="Contract Price"
              value={newDemand.contractPrice || 0}
              onChange={(e) =>
                setNewDemand({ ...newDemand, contractPrice: e.target.value })
              }
            />
          </div>
        </>
      )}
      <div className="formRow">
        <TextField
          fullWidth
          label="Allocation Blend"
          value={newDemand.allocationBlend || ""}
          onChange={(e) =>
            setNewDemand({ ...newDemand, allocationBlend: e.target.value })
          }
        />
      </div>
      {/* <div className="formRow">
        <FormControl fullWidth>
          <InputLabel id="bomType-label">BOM Type</InputLabel>
          <Select
            labelId="bomType-label"
            value={newDemand.bomType}
            onChange={(e) =>
              setNewDemand({ ...newDemand, bomType: e.target.value })
            }
            label="BOM Type"
          >
            <MenuItem value={"Single"}>Single</MenuItem>
            <MenuItem value={"Date Bound"}>Date Bound</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="formRow">
        <TextField
          fullWidth
          label="SKU"
          value={newDemand.sku}
          onChange={(e) => setNewDemand({ ...newDemand, sku: e.target.value })}
        />
      </div> */}
      <Stack
        direction="row"
        spacing={1}
        alignItems={"flex-start"}
        style={{ marginTop: "10px" }}
      >
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => handleCreateNewDemand(newDemand)}
        >
          SAVE
        </Button>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => close()}
        >
          CANCEL
        </Button>
      </Stack>
    </div>
  )
}

export default CreateDemand
