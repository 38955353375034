import React, { useState } from "react"
import { Button, Chip, IconButton } from "@mui/material"
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab"
import MapIcon from "@mui/icons-material/Map"
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt"
import { TaskDataViewer } from "./TaskDataViewer"
import { Map } from "./Map"
import { ProgramEditor } from "./ProgramEditor"
import { EstimatesChart } from "./EstimatesChart"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import { useNavigate } from "react-router-dom"

export const BlockDetail = ({
  block,
  parentSupportedFeatures,
  close,
  reload,
  hasWritePermission,
  onBlockEdit,
  onGeoEdit,
  onBlockDuplicate,
}) => {
  const [showMap, setShowMap] = useState(false)
  const navigate = useNavigate()
  return (
    <div
      className="BlockDetail"
      style={{
        position: "relative",
        paddingBottom: "50px",
      }}
    >
      <div
        style={{
          position: "sticky",
          top: 0,
          backgroundColor: "white",
          padding: "10px",
          boxShadow: "0 2px 4px 0 rgba(0,0,0,0.1)",
          zIndex: 1000,
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "5px",
            marginBottom: "5px",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={() => close()}
            size="small"
            startIcon={<KeyboardTabIcon />}
          >
            Close Detail
          </Button>
          {parentSupportedFeatures && onBlockDuplicate && (
            <Button
              size="small"
              variant="outlined"
              color="primary"
              disabled={!hasWritePermission}
              onClick={() => onBlockDuplicate()}
            >
              Duplicate
            </Button>
          )}
          {parentSupportedFeatures && (
            <Button
              size="small"
              variant="contained"
              color="primary"
              disabled={!hasWritePermission}
              onClick={() => onBlockEdit({ type: "edit", block: block })}
            >
              Edit
            </Button>
          )}
          {parentSupportedFeatures && (
            <IconButton
              size="small"
              onClick={() => onGeoEdit({ block: block })}
              disabled={!hasWritePermission}
              color="primary"
            >
              {block.geo ? <MapIcon /> : <AddLocationAltIcon color="success" />}
            </IconButton>
          )}
        </div>
        <h3>{block.description}</h3>
      </div>
      <div className="body">
        <div className="attributes" style={{ padding: "10px" }}>
          <div className="blockDetailRow">
            <div className="label">ID</div>
            <div>{block.id}</div>
          </div>
          <div className="blockDetailRow">
            <div className="label">Rep</div>
            <div>{block.rep?.name}</div>
          </div>
          <div className="blockDetailRow">
            <div className="label">Vintage</div>
            <div>{block.vintage}</div>
          </div>
          <div className="blockDetailRow">
            <div className="label">Variety</div>
            <div>
              {block.externalContract
                ? block.externalContract.variety?.description
                : block.variety?.description}
            </div>
          </div>
          <div className="blockDetailRow">
            <div className="label">Grower</div>
            <div>
              {block.externalContract
                ? block.externalContract.growerName
                : block.growerName}
            </div>
          </div>
          <div className="blockDetailRow">
            <div className="label">Grower ID</div>
            <div>
              {block.externalContract && block.externalContract.growerId}
            </div>
          </div>
          <div className="blockDetailRow">
            <div className="label">Appellation</div>
            <div>
              {block.externalContract
                ? block.externalContract.appellation?.id
                : block.appellation?.id}
            </div>
          </div>
          <div className="blockDetailRow boundary">
            <div className="label">Acres</div>
            <div>{block.calculated_acres?.toFixed(2)}</div>
          </div>
          <div className="blockDetailRow">
            <div className="label">Contract Status</div>
            <div>{block.contractstatus?.name}</div>
          </div>
          <div className="blockDetailRow">
            <div className="label">Contract</div>
            <div>
              {block.externalContract
                ? block.externalContract.id.substring(
                    0,
                    block.externalContract.id.indexOf("*")
                  )
                : "No Link"}
            </div>
          </div>
          <div className="blockDetailRow">
            <div className="label">Contract Tons</div>
            <div>
              {block.externalContract
                ? block.externalContract.contractTons
                : block.contractTons}
            </div>
          </div>
          <div className="blockDetailRow">
            <div className="label">Delivery Status</div>
            <div>{block.deliverystatus?.name}</div>
          </div>
          {[
            {
              type: "County",
              label: "County",
            },
            {
              type: "Grape District",
              label: "District",
            },
            {
              type: "AVA",
              label: "AVA",
            },
            {
              type: "Water District",
              label: "Water District",
            },
          ].map(({ type, label }) => (
            <div className="blockDetailRow boundary" key={`type${type}`}>
              <div className="label">{label}</div>
              <div>
                {block.blockBoundaries
                  ?.filter((b) => b.boundary.type === type)
                  ?.map(
                    (b) => b.boundary.name + (b.contained ? "" : " (partial)")
                  )
                  .join(", ")}
              </div>
            </div>
          ))}
        </div>
        <div className="">
          <div style={{ padding: "10px" }}>
            {block.geo ? (
              <Button
                onClick={() => setShowMap(!showMap)}
                size="small"
                startIcon={<MapIcon />}
              >
                {showMap ? "Hide Map" : "Show Map"}
              </Button>
            ) : (
              <div>No Map Drawn</div>
            )}
          </div>
          {showMap && block.geo ? (
            <div className="map" style={{ height: "300px", width: "100%" }}>
              <Map
                selectedBlockID={block.id}
                blocks={[block]}
                defaultMapType={"satellite"}
                onBlockClick={() => {}}
              />
            </div>
          ) : null}
        </div>
        <div className="kiptraq section">
          <div
            className="ttt"
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "5px",
            }}
          >
            <h5>KipTraq Data</h5>
            <Chip
              size="small"
              color={block.blockExternalBlocks?.length ? "primary" : "error"}
              label={
                block.blockExternalBlocks?.length
                  ? `${block.blockExternalBlocks?.length} Linked Blocks`
                  : "No Blocks Linked"
              }
            />
          </div>
          <TaskDataViewer
            hasWritePermission={hasWritePermission}
            blockID={block.id}
            block={block}
            afterSave={() => reload()}
          />
        </div>
        <div className="schedule section">
          <div className="ttt">
            <h5>Schedule & Deliveries</h5>
          </div>
          <div style={{ textAlign: "center", marginBottom: "10px" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              endIcon={<CalendarMonthIcon />}
              onClick={() => navigate("/gr/schedule?block=" + block.id)}
            >
              SEE THIS BLOCK'S SCHEDULE
            </Button>
          </div>
          <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="warning"
              size="small"
              endIcon={<CalendarMonthIcon />}
              onClick={() => navigate("/gr/deliveries?block=" + block.id)}
            >
              SEE THIS BLOCK'S DELIVERIES
            </Button>
          </div>
        </div>
        <div className="estimates section">
          <div className="ttt">
            <h5>Estimates</h5>
          </div>
          <div>
            <EstimatesChart block={block} />
          </div>
        </div>
        <div className="programs section">
          <div className="ttt">
            <h5>Programs</h5>
          </div>
          <div>
            <ProgramEditor block={block} readOnly={true} md={{}} />
          </div>
        </div>
      </div>
    </div>
  )
}
