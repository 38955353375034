import { useEffect } from "react"
// import { PermissionsContext } from "../App"
import Page from "../components/Page"
// import { allPermissions, hasPermission } from "../utils/permissions"
import useFetchWithMsal from "../hooks/useFetchWithMsal"
import LinearProgress from "@mui/material/LinearProgress"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import "../styles/SalesOrders.css"
// import Dialog from "@mui/material/Dialog"
// import Box from "@mui/material/Box"
import { useNavigate } from "react-router-dom"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { DataGrid, gridClasses, GridToolbar } from "@mui/x-data-grid"

export const SalesOrdersPage = () => {
  // const [permissions] = useContext(PermissionsContext)
  const navigate = useNavigate()

  const { execute: executeGetOrders, data, isLoading } = useFetchWithMsal()
  // const { execute: executePostCategories, isLoading: posting } =
  //   useFetchWithMsal()

  useEffect(() => {
    if (!data && !isLoading) {
      executeGetOrders("GET", "/api/sales/orders")
    }
  }, [executeGetOrders, data, isLoading])

  return (
    <Page fullHeight className="SalesOrdersPage">
      {isLoading && (
        <div className="loadingBar">
          <LinearProgress />
        </div>
      )}
      <div className="ribbon">
        <Stack direction="row" spacing={1}>
          <div>Your Orders</div>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => navigate("/orders/create")}
            endIcon={<OpenInNewIcon />}
          >
            New Order
          </Button>
        </Stack>
      </div>
      <div className="body">
        <div
          style={{
            width: "100%",
            height: "100%",
            flex: 1,
            maxHeight: "100%",
          }}
          className="gridContainer"
        >
          <DataGrid
            rows={data || []}
            columns={[
              {
                field: "id",
                headerName: "ID",
                width: 60,
              },
              {
                field: "dateOfRequest",
                headerName: "Submitted",
                width: 100,
              },
              {
                field: "requiredDeliveryDate",
                headerName: "Delivery",
                width: 100,
              },
              {
                field: "status",
                headerName: "Status",
                width: 140,
              },
              {
                field: "rName",
                headerName: "Recipient",
                flex: 1,
              },
              {
                field: "products",
                headerName: "Products",
                flex: 1,
                minWidth: 300,
                valueGetter: (params) => {
                  return params.row?.salesOrderItems
                    ?.map((item) => item.productId)
                    .join(", ")
                },
              },
              {
                field: "rState",
                headerName: "State",
                width: 100,
              },
            ]}
            sortModel={[
              {
                field: "id",
                sort: "desc",
              },
            ]}
            hideFooterSelectedRowCount={true}
            disableDensitySelector
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            slots={{ toolbar: GridToolbar }}
            pagination
            getRowHeight={() => "auto"}
            getEstimatedRowHeight={() => 40}
            columnHeaderHeight={40}
            pageSizeOptions={[100]}
            sx={{
              [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                {
                  outline: "none",
                },
              [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                {
                  outline: "none",
                },
            }}
          />
        </div>
      </div>
      {/* <Dialog
        maxWidth="md"
        open={editCategories}
        onClose={() => setEditCategories(false)}
      >
        <Box style={{ padding: "10px" }}>
          <CRUDCategories
            allCategories={data?.allWorkCategories || []}
            createCategory={createCategory}
            updateCategory={updateCategory}
          />
        </Box>
      </Dialog> */}
    </Page>
  )
}
