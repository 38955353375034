import { Button } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import Box from "@mui/material/Box"
import WorkDetail from "./WorkDetail"
import { useState } from "react"

const WorkOverview = ({ demandItems, refresh }) => {
  const [expandedCategories, setExpandedCategories] = useState({})
  const [detailedDemandItem, setDetailedDemandItem] = useState(null)

  const groupedByCategory = {}

  demandItems.forEach((demandItem) => {
    demandItem.plannedWorks.forEach((work) => {
      const categoryId = work.workCategory.id
      if (!groupedByCategory[categoryId]) {
        groupedByCategory[categoryId] = {
          category: work.workCategory,
          demandWorks: [],
        }
      }
      groupedByCategory[categoryId].demandWorks.push({
        ...work,
        demandItem,
      })
    })
  })

  const handleToggleCategory = (categoryId) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [categoryId]: !prev[categoryId],
    }))
  }

  return (
    <div>
      {Object.values(groupedByCategory).map(({ category, demandWorks }) => {
        const groupedByDemandItem = {}

        demandWorks.forEach((dw) => {
          const diId = dw.demandItem.id
          if (!groupedByDemandItem[diId]) {
            groupedByDemandItem[diId] = {
              demandItem: dw.demandItem,
              plannedWorks: [],
            }
          }
          groupedByDemandItem[diId].plannedWorks.push(dw)
        })

        return (
          <div key={category.id} style={{ marginBottom: "1rem" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor: "#eee",
                padding: "0.5rem",
              }}
              onClick={() => handleToggleCategory(category.id)}
            >
              <div style={{ margin: 0, flex: 1 }}>{category.name}</div>
              <span>
                {expandedCategories[category.id] ? "Minimize" : "Expand"}
              </span>
            </div>

            {expandedCategories[category.id] && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {Object.values(groupedByDemandItem).map(
                  ({ demandItem, plannedWorks }) => (
                    <div
                      key={demandItem.id}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        {demandItem.externalSalesContract
                          ? demandItem.externalSalesContract.blend
                          : demandItem.contractBlend}{" "}
                        -{" "}
                        {demandItem.externalSalesContract
                          ? demandItem.externalSalesContract.customerName
                          : demandItem.customer}{" "}
                        -{" "}
                        {demandItem.externalSalesContract
                          ? demandItem.externalSalesContract.contract
                          : ""}{" "}
                      </div>
                      <table style={{ borderCollapse: "collapse" }}>
                        <thead>
                          <tr
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            <th
                              style={{
                                border: "1px solid #ccc",
                                padding: "4px",
                              }}
                            >
                              Start Date
                            </th>
                            <th
                              style={{
                                border: "1px solid #ccc",
                                padding: "4px",
                              }}
                            >
                              End Date
                            </th>
                            <th
                              style={{
                                border: "1px solid #ccc",
                                padding: "4px",
                              }}
                            >
                              Quantity
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {plannedWorks.map((pw, index) => (
                            <tr key={index}>
                              <td
                                style={{
                                  border: "1px solid #ccc",
                                  padding: "4px",
                                }}
                              >
                                {pw.startDate}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ccc",
                                  padding: "4px",
                                }}
                              >
                                {pw.endDate}
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ccc",
                                  padding: "4px",
                                }}
                              >
                                {pw.quantity}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Button onClick={() => setDetailedDemandItem(demandItem)}>
                        View Demand Work Plan
                      </Button>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        )
      })}
      {detailedDemandItem && (
        <Dialog
          fullWidth
          open={!!detailedDemandItem}
          onClose={() => {
            refresh()
            setDetailedDemandItem(null)
          }}
          maxWidth="xl"
        >
          <Box style={{ padding: "10px" }}>
            <WorkDetail
              demand={detailedDemandItem}
              close={() => {
                refresh()
                setDetailedDemandItem(null)
              }}
            />
          </Box>
        </Dialog>
      )}
    </div>
  )
}

export default WorkOverview
