import React from "react"
import useFetchWithMsal from "../hooks/useFetchWithMsal"
import { useEffect } from "react"
import { Button } from "@mui/material"
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid"

const MissingBlocks = ({ onBeginCreate, vintages }) => {
  const { execute: executeLoad, data: contracts } = useFetchWithMsal()

  useEffect(() => {
    if (!contracts) {
      const vintageURLValue = vintages.join(",")
      executeLoad(
        "GET",
        `/api/gr/contracts/missing${
          vintageURLValue ? `?vintage=${vintageURLValue}` : ""
        }`
      )
    }
  }, [executeLoad, vintages, contracts])

  const columns = [
    { field: "id", headerName: "Contract ID", width: 150 },
    { field: "vintage", headerName: "Vintage", width: 80 },
    { field: "varietyCode", headerName: "Variety", width: 80 },
    { field: "appellationCode", headerName: "App", width: 80 },
    { field: "contractTons", headerName: "Tons", width: 80 },
    { field: "location", headerName: "Location", width: 80 },
    { field: "growerId", headerName: "Grower ID", width: 80 },
    { field: "growerName", headerName: "Grower Name", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <Button onClick={() => onBeginCreate(params.row.id)}>
          CREATE BLOCK
        </Button>
      ),
    },
  ]

  if (!contracts) return <div>Loading...</div>

  return (
    <div style={{ width: "100%", height: 500 }}>
      <DataGrid
        rows={contracts}
        columns={columns}
        slots={{
          toolbar: GridToolbarQuickFilter,
        }}
      />
    </div>
  )
}

export default MissingBlocks
