import { Routes, Route } from "react-router-dom"
import { MsalProvider } from "@azure/msal-react"
import { createContext, useState, useEffect } from "react"

import { PageLayout } from "./components/NavigationBar"
import { Home } from "./pages/Home"
import { TodoList } from "./pages/TodoList"
import { GRHome } from "./pages/GRHome"
import { GRMultiYearPage } from "./pages/GRMultiYear"
import { GRSchedule } from "./pages/GRSchedule"
import { Admin } from "./pages/Admin"
import { DataLogPage } from "./pages/GRDataLog"
import { DeliveriesPage } from "./pages/GRDeliveries"
import { DemandPage } from "./pages/Demands"
import { DemandSupplyPage } from "./pages/DemandSupply"
import { QueryPage } from "./pages/QueryTool"
import { WorkPlannerPage } from "./pages/WorkPlanner"

import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"

import "./styles/App.css"
import { TankFarm } from "./pages/TankFarm"

import { createTheme, ThemeProvider } from "@mui/material/styles"
import { grey } from "@mui/material/colors"
import useFetchWithMsal from "./hooks/useFetchWithMsal"
import { B2BPage } from "./pages/B2BDemand"
import { SalesOrdersPage } from "./pages/SalesOrders"
import { CreateSalesOrderPage } from "./pages/CreateSalesOrder"
import { SalesOrdersAdminPage } from "./pages/SalesOrdersAdmin"

export const PermissionsContext = createContext()
const PermissionsProvider = ({ children }) => {
  const [permissions, setPermissions] = useState(null)
  const { execute: executeGetMe, data, isLoading } = useFetchWithMsal()

  useEffect(() => {
    if (!permissions) {
      executeGetMe("GET", "/api/me")
    }
  }, [executeGetMe, permissions])

  useEffect(() => {
    if (data) {
      setPermissions(data)
    }
  }, [data])

  return (
    <PermissionsContext.Provider value={[permissions, setPermissions]}>
      {isLoading && (
        <Backdrop sx={{ color: "#fff", zIndex: 1000 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {children}
    </PermissionsContext.Provider>
  )
}

const theme = createTheme({
  palette: {
    secondary: grey,
  },
})

const Pages = () => {
  return (
    <Routes>
      <Route path="/gr" element={<GRHome />} />
      <Route path="/gr/longterm" element={<GRMultiYearPage />} />
      <Route path="/gr/schedule" element={<GRSchedule />} />
      <Route path="/gr/data" element={<DataLogPage />} />
      <Route path="/gr/deliveries" element={<DeliveriesPage />} />
      <Route path="/todolist" element={<TodoList />} />
      <Route path="/tankfarm" element={<TankFarm />} />
      <Route path="/demand/cg/supplyplan" element={<DemandSupplyPage />} />
      <Route path="/demand/cg" element={<DemandPage />} />
      <Route path="/demand/b2b" element={<B2BPage />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/tools/queries" element={<QueryPage />} />
      <Route path="/work/categories" element={<WorkPlannerPage />} />
      <Route path="/orders" element={<SalesOrdersPage />} />
      <Route path="/orders/create" element={<CreateSalesOrderPage />} />
      <Route path="/orders/admin" element={<SalesOrdersAdminPage />} />
      <Route path="/" element={<Home />} />
    </Routes>
  )
}

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App = ({ instance }) => {
  return (
    <ThemeProvider theme={theme}>
      <MsalProvider instance={instance}>
        <PermissionsProvider>
          <PageLayout>
            <Pages />
          </PageLayout>
        </PermissionsProvider>
      </MsalProvider>
    </ThemeProvider>
  )
}

export default App
