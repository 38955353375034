import React, { useState } from "react"
import { Button } from "@mui/material"
import Stack from "@mui/material/Stack"
import { OutlinedInput } from "@mui/material"

export const DuplicateBlock = ({ block, onCancel, onSave }) => {
  const [vintage, setVintage] = useState("2025")
  const [error, setError] = useState(null)

  const save = () => {
    if (!vintage || vintage.length !== 4) {
      setError("Invalid vintage")

      return
    }

    onSave(vintage)
  }

  if (!block) {
    return null
  }

  return (
    <div className="EstimateEditor" style={{ padding: "10px" }}>
      <div className="header">Duplicate Block</div>
      <div>{block.description}</div>
      {error && <div style={{ color: "red", fontWeight: "bold" }}>{error}</div>}
      <div>
        <div className="formRow">
          <OutlinedInput
            value={vintage}
            type="number"
            onChange={(e) => setVintage(e.target.value)}
            fullWidth
            id="tonsestimate"
          />
        </div>
      </div>
      <div>
        <Stack style={{ margin: "20px 0 10px 0" }} direction="row" spacing={1}>
          <Button
            disabled={!vintage}
            variant="contained"
            color="primary"
            onClick={() => save()}
          >
            SAVE
          </Button>
          <Button variant="outline" color="secondary" onClick={onCancel}>
            CANCEL
          </Button>
        </Stack>
      </div>
    </div>
  )
}
