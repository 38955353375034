import { useContext, useEffect, useState } from "react"
import { PermissionsContext } from "../App"
import Page from "../components/Page"
import { allPermissions, hasPermission } from "../utils/permissions"
import useFetchWithMsal from "../hooks/useFetchWithMsal"
import LinearProgress from "@mui/material/LinearProgress"
import "../styles/QueryTool.css"
import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import Stack from "@mui/material/Stack"
import { DataGrid } from "@mui/x-data-grid"

export const QueryPage = () => {
  const [permissions] = useContext(PermissionsContext)
  const {
    execute,
    data: queryList,
    isLoading: listLoading,
  } = useFetchWithMsal()
  const {
    execute: executeQuery,
    isLoading: queryLoading,
    data: queryData,
  } = useFetchWithMsal()

  const [selectedQuery, setSelectedQuery] = useState(null)

  useEffect(() => {
    if (!queryList && !listLoading) {
      execute("GET", "/api/tools/queries")
    }
  }, [execute, queryList, listLoading])

  const handleQueryChange = (event) => {
    const newValue = event.target.value
    if (newValue === "") {
      setSelectedQuery(null)
      return
    }
    setSelectedQuery(event.target.value)
  }

  const runQuery = () => {
    if (selectedQuery) {
      executeQuery("POST", `/api/tools/queries/${selectedQuery}`)
    }
  }

  if (hasPermission(permissions, [allPermissions.QUERYTOOL_READ])) {
    return (
      <Page fullHeight className="QueryPage">
        {(listLoading || queryLoading) && (
          <div className="loadingBar">
            <LinearProgress />
          </div>
        )}
        <div className="ribbon">
          <Stack direction="row" spacing={1}>
            <div
              style={{
                minWidth: "300px",
              }}
            >
              <FormControl fullWidth>
                <Select
                  value={selectedQuery || ""}
                  placeholder="Select a query"
                  onChange={handleQueryChange}
                >
                  {queryList?.map((query) => (
                    <MenuItem key={query.id} value={query.id}>
                      {query.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {selectedQuery && (
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={runQuery}
                disabled={queryLoading}
              >
                RUN QUERY
              </Button>
            )}
          </Stack>
        </div>
        <div className="body">
          {queryData && (
            <DataGrid
              rows={queryData.results}
              columns={queryData.query.columns.map((col) => ({
                field: col.field,
                headerName: col.name,
                width: 150,
              }))}
              pageSize={10}
              rowsPerPageOptions={[10, 20, 50]}
              disableSelectionOnClick
            />
          )}
        </div>
      </Page>
    )
  } else {
    return <Page>NOT ALLOWED</Page>
  }
}
