import React, { useEffect, useState } from "react"
import Page from "../components/Page"
import useFetchWithMsal from "../hooks/useFetchWithMsal"
import LinearProgress from "@mui/material/LinearProgress"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import "../styles/GRMultiYear.css"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { useNavigate } from "react-router-dom"
import { Dialog, Box } from "@mui/material"
import { BlockEditor } from "../components/BlockEditor"
import IconButton from "@mui/material/IconButton"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import { BlockDetail } from "../components/BlockDetail"
import { EstimateEditor } from "../components/EstimateEditor"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import RefreshIcon from "@mui/icons-material/Refresh"

const DATA_URL = "/api/gr/longterm"

/*
This page will display a grid. 
The data will come in as a list of blocks.
The rows will be the groups of blocks.
The columns will be the years (vintage), starting with the previous year and default extending to 5 years from the current year. 

Cells depend on if it is a block or a group.
If it is a group, the entire row should be expandable.
If not expanded, it will aggregate the data for the group.
Block Cells depend if there is a block for that vintage
if yes: 
  show contract, estimate, delivered
  allow for quick estimate if > crushed vintages
if no:
  (possibly) indicate whether there is a contract detail
  button for quick create

data is in form of:
[
{
  blockID, vintage, varietyCode, varietyDesc, appellationCode, 
  growerName, growerId, contractID,
	contract, rep, contractTons, latestEstimatedTons, deliveredTons
}
]
*/

const groupHierarchy = [
  "varietyCode",
  "appellationCode",
  "growerName",
  "contract",
]

const baseYear = new Date().getFullYear() - 1
const currentYear = new Date().getFullYear()

const formatNumber = (num) => {
  if (!num) {
    return num
  }
  // 22,233,222 no decimals, need rounding
  return num.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

// const getAllBlocksInGroup = (rows) => {
//   const blocks = []
//   for (const row of rows) {
//     if (row.isGroup) {
//       blocks.push(...getAllBlocksInGroup(row.children))
//     }
//     if (!row.isGroup) {
//       blocks.push(row)
//     }
//   }
//   return blocks
// }

const Row = ({
  node,
  onBlockSelect,
  onBlockEstimateEdit,
  onBlockCreateFromExistingOrExternal,
  createFromExistingOrExternal: createFromExistingOrExternalProp,
  valueSelector,
}) => {
  const isGroup = node.isGroup
  const [expanded, setExpanded] = React.useState(false)

  const renderColumnCell = (vintage, node) => {
    // const blocks = getAllBlocksInGroup(rows)
    // const thisVintageBlocks = blocks.filter((b) => b.vintage === vintage)
    // const summedContract = thisVintageBlocks.reduce(
    //   (acc, b) => acc + b.contractTons,
    //   0
    // )
    // const summedEstimate = thisVintageBlocks.reduce(
    //   (acc, b) => acc + b.latestEstimatedTons,
    //   0
    // )
    // const summedDelivered = thisVintageBlocks.reduce(
    //   (acc, b) => acc + b.deliveredTons,
    //   0
    // )
    const contractTons = node.totals
      ? node.totals[vintage]?.contractTons
      : vintage === node.vintage
      ? node.contractTons
      : null
    const latestEstimatedTons = node.totals
      ? node.totals[vintage]?.latestEstimatedTons
      : vintage === node.vintage
      ? node.latestEstimatedTons
      : null
    const deliveredTons = node.totals
      ? node.totals[vintage]?.deliveredTons
      : vintage === node.vintage
      ? node.deliveredTons
      : null

    return (
      <td
        key={`${node.value}${vintage}`}
        className={`yearCol ${
          vintage < currentYear
            ? "past"
            : vintage === currentYear
            ? "current"
            : "future"
        }`}
        title={`${vintage} - Est: ${formatNumber(
          latestEstimatedTons || 0
        )} - Con: ${formatNumber(contractTons || 0)} `}
      >
        {formatNumber(
          vintage < currentYear
            ? deliveredTons
            : vintage === currentYear
            ? valueSelector.current === "estimate"
              ? latestEstimatedTons
              : contractTons
            : valueSelector.future === "estimate"
            ? latestEstimatedTons
            : contractTons
        )}
      </td>
    )
  }

  const createFromExistingOrExternal = (vintage, contractId) => {
    // the existingId is the blockID of the block that is being created from
    // this will be the block with the latest vintage that has a blockID
    const existingId = node.children
      .filter((b) => b.blockID)
      .sort((a, b) => b.vintage - a.vintage)[0]?.blockID

    onBlockCreateFromExistingOrExternal(vintage, existingId, contractId)
  }

  return (
    <>
      <tr
        style={{
          backgroundColor:
            node.level === 0
              ? "#f0f0f0"
              : node.level === 1
              ? "#e0e0e0"
              : "white",
          fontWeight: isGroup ? "bold" : "normal",
        }}
      >
        <td className="blocksCol">
          <div
            onClick={() => {
              if (isGroup) {
                setExpanded(!expanded)
              }
            }}
            style={{
              cursor: isGroup ? "pointer" : "default",
              backgroundColor:
                !isGroup && !node.blockID ? "rgb(255, 130, 130)" : "auto",
              padding: "1px",
            }}
          >
            <div
              style={{
                paddingLeft: `${node.level * 10}px`,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {isGroup
                ? `${node.value || "None"}`
                : `${node.vintage} - ${
                    node.blockID ? node.description : "Not added to Port"
                  }`}
            </div>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "flex-end",
                gap: "5px",
                minWidth: "80px",
              }}
            >
              {node.children ? (
                `(${node.children.length})`
              ) : node.blockID ? (
                <>
                  {node.vintage >= currentYear && (
                    <button
                      onClick={() => {
                        onBlockEstimateEdit(node)
                      }}
                    >
                      Estimate
                    </button>
                  )}
                  <button
                    onClick={() => {
                      onBlockSelect(node.blockID)
                    }}
                  >
                    Detail
                  </button>
                </>
              ) : node.vintage >= currentYear ? (
                <button
                  onClick={() => {
                    createFromExistingOrExternalProp(
                      node.vintage,
                      node.contractID
                    )
                  }}
                >
                  Add Block
                </button>
              ) : (
                ""
              )}{" "}
            </div>
          </div>
        </td>
        {Array.from({ length: 6 }, (_, i) => {
          return renderColumnCell(baseYear + i, node)
        })}
      </tr>
      {expanded &&
        node.children
          ?.sort((a, b) => {
            // sort by value or vintage
            if (a.isGroup) {
              return a.value?.localeCompare(b.value)
            }
            return a.vintage - b.vintage
          })
          .map((child, i) => (
            <Row
              key={`${child.isGroup ? "group" : "block"}-${
                child.value
                  ? child.value + "val"
                  : !child.blockID
                  ? i + "noblock"
                  : child.blockID + "blockid"
              }`}
              node={child}
              onBlockSelect={onBlockSelect}
              onBlockEstimateEdit={onBlockEstimateEdit}
              onBlockCreateFromExistingOrExternal={
                onBlockCreateFromExistingOrExternal
              }
              createFromExistingOrExternal={createFromExistingOrExternal}
              valueSelector={valueSelector}
            />
          ))}
    </>
  )
}

const Grid = ({
  data,
  onBlockSelect,
  onBlockEstimateEdit,
  onBlockCreateFromExistingOrExternal,
  valueSelector,
}) => {
  const groupedData = data.reduce((acc, block) => {
    let current = acc
    let level = 0
    for (const field of groupHierarchy) {
      const value = block[field]
      let group = current.find((g) => g.value === value)
      if (!group) {
        group = {
          isGroup: true,
          field,
          value,
          children: [],
          level,
          totals: {}, // Initialize totals object to store vintage-specific values
        }
        current.push(group)
      }
      // Initialize vintage totals if they don't exist
      if (!group.totals[block.vintage]) {
        group.totals[block.vintage] = {
          contractTons: 0,
          latestEstimatedTons: 0,
          deliveredTons: 0,
        }
      }
      // Add block values to group totals for specific vintage
      group.totals[block.vintage].contractTons += block.contractTons || 0
      group.totals[block.vintage].latestEstimatedTons +=
        block.latestEstimatedTons || 0
      group.totals[block.vintage].deliveredTons += block.deliveredTons || 0

      current = group.children
      level++
    }
    current.push({ ...block, level })
    return acc
  }, [])

  return (
    <div className="GridCont">
      <table>
        <thead>
          <tr>
            <th className="blocksCol">Block Info</th>
            {Array.from({ length: 6 }, (_, i) => {
              return (
                <th className="yearCol" key={`year${baseYear + i}`}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="year">{baseYear + i}</div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "0.8rem",
                        padding: "0 5px",
                      }}
                    >
                      <span>
                        {baseYear + i === currentYear - 1 ? "D:" : "E:"}
                      </span>
                      <span>
                        {formatNumber(
                          groupedData.reduce(
                            (acc, node) =>
                              acc +
                              (node.totals[baseYear + i]?.[
                                baseYear + i === currentYear - 1
                                  ? "deliveredTons"
                                  : "latestEstimatedTons"
                              ] || 0),
                            0
                          )
                        )}{" "}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "0.8rem",
                        padding: "0 5px",
                      }}
                    >
                      <span>C:</span>
                      <span>
                        {formatNumber(
                          groupedData.reduce(
                            (acc, node) =>
                              acc +
                              (node.totals[baseYear + i]?.["contractTons"] ||
                                0),
                            0
                          )
                        )}{" "}
                      </span>
                    </div>
                  </div>
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {groupedData
            .sort(
              (a, b) =>
                b.totals[currentYear]?.[
                  valueSelector.current === "estimate"
                    ? "latestEstimatedTons"
                    : "contractTons"
                ] -
                a.totals[currentYear]?.[
                  valueSelector.current === "estimate"
                    ? "latestEstimatedTons"
                    : "contractTons"
                ]
            )
            .map((node) => {
              return (
                <Row
                  onBlockSelect={onBlockSelect}
                  key={`top${node.field}-${node.value}`}
                  node={node}
                  onBlockEstimateEdit={onBlockEstimateEdit}
                  onBlockCreateFromExistingOrExternal={
                    onBlockCreateFromExistingOrExternal
                  }
                  valueSelector={valueSelector}
                />
              )
            })}
        </tbody>
      </table>
    </div>
  )
}

export const GRMultiYearPage = () => {
  const navigate = useNavigate()
  const [blockEditor, setBlockEdit] = useState(null)
  const [saveBlockError, setSaveBlockError] = useState(null)
  const [selectedBlock, setSelectedBlock] = useState(null)
  const [blockEstimateEditor, setBlockEstimateEdit] = useState(null)
  const [valueSelector, setValueSelector] = useState({
    current: "estimate",
    future: "contract",
  })

  const { execute: executeGetData, isLoading, data } = useFetchWithMsal()
  const { execute: executeSaveBlock, isLoading: isSaving } = useFetchWithMsal()
  const {
    execute: executeLoadMD,
    isLoading: isLoadingMD,
    data: md,
  } = useFetchWithMsal()
  const { execute: executeGetBlocks } = useFetchWithMsal()
  const { execute: executeSaveBlockEstimates } = useFetchWithMsal()

  useEffect(() => {
    executeGetData("GET", DATA_URL)
  }, [executeGetData])

  useEffect(() => {
    if (!md) {
      executeLoadMD("GET", "/api/gr/md")
    }
  }, [executeLoadMD, md])

  const saveBlock = async (block, validate) => {
    if (validate) {
      if (!block.description) {
        setSaveBlockError("Description is required")
        return
      }
    }
    await executeSaveBlock("POST", "/api/gr/blocks", block)
    setBlockEdit(null)
    setSaveBlockError(null)
    executeGetData("GET", DATA_URL)
  }

  const onBlockSelect = async (blockID) => {
    if (blockID) {
      if (selectedBlock && selectedBlock.id === blockID) {
        return
      }
      const resp = await executeGetBlocks(
        "GET",
        `/api/gr/blocks?blockID=${blockID}`
      )
      if (resp && resp.length > 0) {
        setSelectedBlock(resp[0])
      }
    } else {
      setSelectedBlock(null)
    }
  }

  const saveBlockEstimates = async (id, newEstimate) => {
    await executeSaveBlockEstimates("POST", "/api/gr/blocks/estimates", {
      blockId: id,
      estimate: newEstimate,
    })
    setBlockEstimateEdit(null)
    executeGetData("GET", DATA_URL)
  }

  const createDuplicateBlock = async (vintage, existingId, contractId) => {
    const resp = await executeSaveBlock("POST", "/api/gr/blockdup", {
      vintage,
      existingId,
      contractId,
    })
    if (resp) {
      // setSelectedBlock(resp)
    }
    executeGetData("GET", DATA_URL)
  }

  const filteredData = data
    ? data.filter((d) => d.vintage >= baseYear && d.vintage <= baseYear + 5)
    : []

  return (
    <Page fullHeight className="GRMultiYearPage">
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
        }}
      >
        {(isLoading || isSaving) && (
          <div className="loadingBar">
            <LinearProgress />
          </div>
        )}
        <div className="ribbon">
          <Stack direction="row" spacing={1}>
            <IconButton
              size="small"
              variant="contained"
              color="primary"
              onClick={() => executeGetData("GET", DATA_URL)}
            >
              <RefreshIcon />
            </IconButton>
            <IconButton
              size="small"
              variant="contained"
              color="primary"
              onClick={() => setBlockEdit({ type: "new" })}
              // disabled={!hasWritePermission}
            >
              <AddCircleIcon />
            </IconButton>
            <Stack direction="row" spacing={1}>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                }}
              >
                <div>
                  <span>{currentYear}</span>
                </div>
                <ToggleButtonGroup
                  value={valueSelector.current}
                  exclusive
                  onChange={(e, v) =>
                    setValueSelector((prev) => ({ ...prev, current: v }))
                  }
                  size="small"
                >
                  <ToggleButton size="small" value="estimate">
                    Estimate
                  </ToggleButton>
                  <ToggleButton size="small" value="contract">
                    Contract
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                }}
              >
                <div>
                  <span>Future</span>
                </div>
                <ToggleButtonGroup
                  value={valueSelector.future}
                  exclusive
                  onChange={(e, v) =>
                    setValueSelector((prev) => ({ ...prev, future: v }))
                  }
                  size="small"
                >
                  <ToggleButton size="small" value="estimate">
                    Estimate
                  </ToggleButton>
                  <ToggleButton size="small" value="contract">
                    Contract
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => navigate("/gr")}
              endIcon={<OpenInNewIcon />}
            >
              Blocks
            </Button>
          </Stack>
        </div>
        <div
          className="body"
          style={{
            flex: "1",
            minHeight: "0",
            overflowY: "auto",
          }}
        >
          {data && (
            <Grid
              data={filteredData}
              onBlockSelect={(blockID) => onBlockSelect(blockID)}
              onBlockEstimateEdit={(block) => setBlockEstimateEdit(block)}
              onBlockCreateFromExistingOrExternal={(
                vintage,
                existingId,
                contractId
              ) => {
                console.log("Create from existing vintage", vintage)
                console.log("Create from existing existingId", existingId)
                console.log("Create from existing contractId", contractId)
                createDuplicateBlock(vintage, existingId, contractId)
              }}
              valueSelector={valueSelector}
            />
          )}
        </div>
      </div>
      {selectedBlock && (
        <div className="BlockDetailCont blockDetailContainer ">
          <BlockDetail
            block={selectedBlock}
            close={() => setSelectedBlock(null)}
            reload={() => onBlockSelect(selectedBlock.id)}
            hasWritePermission={true}
            onBlockEdit={() => {}}
            onGeoEdit={() => {}}
          />
        </div>
      )}
      {blockEditor && (
        <Dialog
          fullWidth
          open={!!blockEditor}
          onClose={() => setBlockEdit(null)}
        >
          <Box>
            <BlockEditor
              onCancel={() => setBlockEdit(null)}
              block={blockEditor?.block}
              error={saveBlockError}
              onSave={(b) => {
                saveBlock(b, true)
              }}
              md={md}
              isLoadingMD={isLoadingMD}
              prePopulated={blockEditor?.prePopulated || {}}
            />
          </Box>
        </Dialog>
      )}
      {blockEstimateEditor && (
        <Dialog
          open={!!blockEstimateEditor}
          onClose={() => setBlockEstimateEdit(null)}
        >
          <Box>
            <EstimateEditor
              onCancel={() => setBlockEstimateEdit(null)}
              block={{
                ...blockEstimateEditor,
                id: blockEstimateEditor.blockID,
              }}
              onSave={(id, p) => saveBlockEstimates(id, p)}
            />
          </Box>
        </Dialog>
      )}
    </Page>
  )
}
