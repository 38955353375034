import React from "react"
import "../styles/ProductPicker.css"

/*
This component will be used to pick products from a provided list.
The provided list is in this format:
[
  {
    id: "20MIRLI751200",
    description: "20 Line 39 Mixed Red - 750ml 12pk",
    brand: "LINE 39"
    varietal: "Mixed Red",
    size: "750ml",
    package: "12pk",
    modification: null,
    vintage: "20",
  },
]
The picker should allow user to filter list by brand, varietal, size, package, and vintage.
*/
const DropDown = ({ options, value, onChange, className }) => {
  return (
    <select
      className={className}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {`${option.label}${
            option.value && option.value !== option.label
              ? ` (${option.value})`
              : ""
          }`}
        </option>
      ))}
    </select>
  )
}

export const ProductPicker = ({ products, onChange, id }) => {
  const [brand, setBrand] = React.useState("")
  const [varietal, setVarietal] = React.useState("")
  const [size, setSize] = React.useState("")

  if (!products) {
    return null
  }

  // Filter products based on current selections
  const getFilteredProducts = (excludeField) => {
    return products.filter(
      (product) =>
        (!brand || excludeField === "brand" || product.brand === brand) &&
        (!varietal ||
          excludeField === "varietal" ||
          product.varietal === varietal) &&
        (!size || excludeField === "size" || product.size === size)
    )
  }

  // Get unique options for each field based on filtered products
  const getOptions = (field, excludeField) => {
    const filteredProducts = getFilteredProducts(excludeField)
    return ["", ...new Set(filteredProducts.map((p) => p[field]))]
      .sort((a, b) => a.localeCompare(b))
      .map((v) => ({
        value: v,
        label: v,
      }))
  }

  const filteredProducts = getFilteredProducts()

  return (
    <div className="ProductPicker">
      <div>Select a SKU. Filter the list using the dropdowns below.</div>
      <div className="product">
        <DropDown
          options={[
            {
              value: "",
              label: "Select a product",
            },
            ...filteredProducts.map((product) => ({
              value: product.id,
              label: product.description,
            })),
          ]}
          value={id || ""}
          onChange={onChange}
        />
      </div>
      <div>Filters</div>
      <div className="filters">
        <DropDown
          options={getOptions("brand", "brand")}
          value={brand}
          onChange={setBrand}
          className="brand"
        />
        <DropDown
          options={getOptions("varietal", "varietal")}
          value={varietal}
          onChange={setVarietal}
          className="varietal"
        />
        <DropDown
          options={getOptions("size", "size")}
          value={size}
          onChange={setSize}
          className="size"
        />
      </div>
    </div>
  )
}
